.scanButton {
  width: 300px;
  padding: 18px 20px;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 18px;
  background: transparent;
  text-align: center;
  color: black;
}
.scannerContainer {
  width: 100vw;
  min-height: calc(100vh - 163px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.scannerContainerActive {
  justify-content: flex-start;
  margin-top: 30px;
}
.scannerBlock {
  width: 90%;
  border: 2px solid black;
  -webkit-box-shadow: 0 3px 48px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 3px 48px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0 3px 48px -11px rgba(0, 0, 0, 0.75);
}
