.buttons,
.clearButton,
.fastOrderButton,
.backButton,
.sendManagerButton {
  min-width: 30px;
  padding: 18px 20px;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 900;
  background: transparent;
  text-align: center;
  color: black;
  display: block;
}
.fastOrderButton {
  width: 100%;
  border-color: blue;
  color: blue;
}
.buttons {
  padding: 11px 20px;
  border-radius: 10px;
}
.deleteProductBtn {
  padding: 9px 20px;
  height: 45px;
}
.clearButton {
  border-color: #f35050;
  color: #f35050;
  width: 100%;
}
.sendManagerButton {
  border-color: darkslateblue;
  color: darkslateblue;
  width: 100%;
}
.backButton {
  color: black;
  border-color: black;
  font-size: 18px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
}
.basketButtonBlock,
.basketTitleBlock {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  width: 100%;
}
.basketTitleBlock {
  padding: 5px;
  border-radius: 10px;
  color: white;
  box-sizing: border-box;
  font-weight: 700;
}
.basketTitleBlock.inStock {
  background: #7d7;
}
.basketTitleBlock.inProduction {
  background: orange;
}
.basketTitleBlock.forwardOrder {
  background: #003f80;
}
.basketTitleBlock span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 129px;
}
.basketItem {
  display: flex;
  gap: 10px;
  width: 100%;
}
.basketImage {
  width: 60px;
}
.basketList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.basketTitle {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin: 0;
}
.basketContent {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 80px);
  gap: 10px;
}
.basketItemCount {
  max-width: 30px;
  text-align: center;
  display: block;
}
.basketPage {
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.basketForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.inputField {
  width: 250px;
  outline: none;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 10px;
}
.basketModalTitle {
  font-size: 24px;
  margin: 0;
}
.basketSubmitBlock {
  display: flex;
  gap: 10px;
}
.productCode {
  font-size: 18px;
  font-weight: bold;
}
.basketCustomerName {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
