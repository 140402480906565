.scanInput {
  width: calc(100% - 70px);
  padding: 18px 0;
  border: 1px solid black;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 18px;
  background: transparent;
  text-align: center;
  outline: none;
  color: black;
}
.scanBtn {
  min-width: 100px;
  padding: 18px 0;
  border: 1px solid black;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 18px;
  background: transparent;
  text-align: center;
  color: black;
  border-left: none;
}
.scanForm {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
}
.scanHint {
  position: absolute;
  top: -25px;
}
