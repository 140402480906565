.infoModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.41);
}
.infoModalContainer {
  background: white;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.infoModalText {
  margin: 0;
  padding: 0;
  font-size: 24px;
}
.infoModalButton {
  width: 100px;
  padding: 18px 20px;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 18px;
  background: transparent;
  text-align: center;
  color: black;
}
