.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.logoBlock {
  display: flex;
  align-items: center;
  gap: 10px;
}
.headerTitle {
}
.basketLink {
  position: relative;
}
.basketHeaderCount {
  position: absolute;
  background: black;
  min-width: 20px;
  height: 20px;
  padding: 3px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  right: -2px;
  bottom: -1px;
  font-weight: 800;
  border-radius: 50%;
}
.headerLogo {
  width: 150px;
}
.userNameBlock {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}
