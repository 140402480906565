.loginWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 100vh;
  flex-direction: column;
}
.loginError {
  color: #d23a3a;
  font-weight: bold;
}
.loginLogo {
  width: 200px;
  margin-bottom: 30px;
}
