.productModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.41);
}
.productModalContainer {
  max-height: 100%;
  max-width: 100%;
  background: white;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;
  gap: 12px;
}
.productModalText {
  margin: 0;
  padding: 0;
  font-weight: 800;
}
.productModalButton {
  min-width: 100px;
  padding: 18px 20px;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 18px;
  background: transparent;
  text-align: center;
  color: black;
}
.productModalButton[disabled] {
  opacity: 0.5;
}
.productModalButtonContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.productModalImage {
  width: 60%;
}
.loadingModal {
  width: 100vw;
  min-height: 400px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.productSizeChip {
  padding: 8px 15px;
  border-radius: 4px;
  border: 6px solid transparent;
  font-size: 18px;
  text-align: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.inProd {
  border-color: orange;
  background-color: orange;
  opacity: 0.5;
}

.inStock {
  border-color: #7d7;
  background-color: #7d7;
  opacity: 0.5;
}

.inOrder {
  border-color: #003f80;
  background-color: #003f80;
  opacity: 0.5;
}

.productSizeChipActive.inProd {
  opacity: 1;
}
.productSizeChipActive.inStock {
  opacity: 1;
}
.productSizeChipActive.inOrder {
  opacity: 1;
}
.productModalSizes {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
}
.productModalColors {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
}
.disableProductModalWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.41);
}

.disableProductModal {
  background: white;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.disableProductModalText {
  font-size: 24px;
  text-align: center;
}
.productRestricted {
  border-color: gray;
  background: gray;
  opacity: 0.5;
}
.linkWhatsApp {
  display: block;
}
.FODisabled,
.productIsDiscontinued {
  border-color: gray;
  background: gray;
  opacity: 0.3;
}

.selectedSizes {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
}

.selectedSizesTitle {
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.selectedSizesList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.selectedSizeItem {
  font-size: 14px;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-evenly;
}

.selectedSizeItem:last-child {
  border-bottom: none;
}

.size {
  font-weight: bold;
  color: #333;
  width: 66px;
}

.eta {
  font-weight: bold;
}
